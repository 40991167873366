/* ServicesSection.css */
.services-section {
  height: 94vh;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Creates 4 columns */
  grid-auto-rows: auto; /* Rows size based on content */
  gap: 20px; /* Adjust the space between grid items */
  padding: 20px; /* Adds some padding around the grid */
  justify-items: center; /* Center grid items horizontally */
  align-items: start; /* Align grid items to the start of each row */
  background-color: aliceblue; /* Sets the background color to white */
}

.title {
  color: #0f1e32;
  grid-column: 1 / -1; /* Ensures the title spans all columns */
  text-align: center; /* Center the title text */
  width: 100%;
  font-size: 4vh;
  margin-bottom: -20px; /* Adds space between the title and the first row of cards */
}


@media only screen and (max-width: 431px) {
  .services-section {
    height: 275vh;
    border-radius: 20px;
    padding-bottom: 15vh;
    width: 54vw; /* Sets the width to be 50% of the viewport width */
    margin: 0 auto; /* Centers the section horizontally */
    grid-template-columns: repeat(1, 1fr); /* Keeps a single column layout */
    /* Use a media query if you want to adjust to 2 columns at a certain breakpoint, e.g., more than 431px */
  }

  .title {
    font-size: 3vh;
    margin-top: -5px;
    margin-bottom: 20px;
  }
}

