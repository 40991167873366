/* Global styles */
/* Remove default link styles */
a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Optional: Makes the link use the text color of its parent */
  cursor: pointer; /* Ensures it looks clickable, even without the underline */
}

/* You can also target only those <a> tags that wrap buttons, if they have a specific class */
a.button-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

body, html {
  margin: 0;
  padding: 0;
  scroll-snap-type: y mandatory; /* Enables vertical scroll snapping */
  overflow-y: scroll; /* Ensures the body can scroll */
  height: 100vh; /* Full viewport height */
  background-color: #0f1e32
}

/* Container for Header and HeroSection */
.header-hero-container {
  background-color: aliceblue;
  height: 100vh; /* Full viewport height */
  scroll-snap-align: start; /* Aligns this container to the start of the viewport when snapping */
}

/* Container for ServicesSection */
.services-container {
  border-radius: 10px;
  height: 100vh; /* Full viewport height */
  scroll-snap-align: start; /* Aligns this container to the start of the viewport when snapping */
  padding: 1vh 1vh 3vh 1vh;
}

/* Container for ContactForm and Footer */
.contact-footer-container {
  border-radius: 10px;
  height: 100vh; /* Full viewport height, combined height of ContactForm and Footer */
  scroll-snap-align: start; /* Aligns this container to the start of the viewport when snapping */padding: 1vh 1vh 3vh 1vh;
  padding: 1vh 1vh 7vh 1vh;
}

/* Additional styles might be needed for internal components to ensure they fit within their containers */
@media only screen and (max-width: 431px) {
  .services-container {
    height: 330vh;
  }
}