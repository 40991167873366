/* Base styles */
.contact-page-container {
  display: flex;
  flex-direction: row; /* side-by-side layout */
  align-items: stretch; /* Align items vertically */
  text-align: center;
  height: 80vh;
  max-height: 100%; /* Ensure it doesn't overflow the viewport */
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56vw; /* Half of the viewport width for desktop */
  padding: 2vh 0;
  background-color: aliceblue;
}

/* Contact form styling */
.contact-title {
  color: #0f1e32;
  font-size: 4vh;
  margin-bottom: 20px; /* Add some space below the title */
}

h3 {
  font-size: 3vh;
  color: #0f1e32;
  margin-bottom: -15px; 
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 80%;
  max-width: 500px; /* Ensure the form doesn't get too wide */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.8rem;
  background-color: #e1eaf2;
  border: 2px solid #ccc; /* Make border slightly thicker */
  border-radius: 8px;
  box-sizing: border-box;
}

/* Focus state for inputs and textarea */
.contact-form input:focus,
.contact-form textarea:focus {
  outline: none; /* Remove default outline */
  border-color: navy; /* Change border color to navy on focus */
}

/* Button styling */
.contact-form button {
  padding: 0.8rem 1.2rem; /* Make the button larger */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-page-container {
    flex-direction: column;
    height: auto; /* Adjust height based on content */
  }

  .contact-image,
  .contact-form-container {
    width: 95.6vw; /* Full width on mobile */
    height: 50vh; /* Adjust to half of viewport height */
  }

  .contact-form-container {
    padding: 4vh 0; /* Increase padding on mobile */
  }

  .contact-title{
    font-size: 3vh;
  } 

  h3 {
    font-size: 1.3vh;
    color: #0f1e32;
    margin-bottom: -46px; 
  }
}
