.header {
  height: 13vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 2vw;
  background-color: rgba(255, 255, 255, 0.4); /* Make it white with 50% opacity */
  box-shadow: 0 0.2vh 0.4vw rgba(0, 0, 0, 0.1);
  position: relative; /* This is critical for z-index to work */
  z-index: 2; /* Ensures the header is above the hero section */
}

.logo {
  height: 140%; /* Make the logo fill the height of the header */
  max-height: 25vh; /* Ensures the logo doesn't exceed the header's height */
}

.header-controls {
  display: flex;
  align-items: center;
  justify-content: space-between; /* This ensures equal spacing */
  width: auto; /* Adjust based on content size */
}

.social-icons a, .nav-buttons button {
  display: inline-flex; /* Use inline-flex for aligning icons and text properly */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center content horizontally */
  margin: 0 1vh; /* Use vh to keep spacing reactive */
  font-size: 2vh; /* Use vh to make font size reactive */
  height: 4vh; /* Set a reactive height for icons and buttons */
  width: 4vh; /* Set a reactive width for icons */
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits the text color from the parent element */
}

.nav-buttons button {
  border-radius: 10px;
  width: auto; /* Allow button width to adjust based on content */
  padding: 0 1vh; /* Use vh to keep padding reactive */
  border: none; /* Removes the border */
  background-color: #0066cc; /* Initial background color */
  color: white; /* Text color */
  cursor: pointer; /* Changes cursor to pointer on hover */
  font-size: 2vh; /* Ensure text size is responsive */
  transition: background-color 0.3s ease; /* Smooth transition for the background color */
}

.nav-buttons button:hover {
  background-color: #66cc00; /* Color transitions to green on hover */
}

/* Removing default link styling for icons */
.social-icons a {
  font-size: 4vh;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  font-weight: bold;
  color: #0066cc;
  background: none; /* Ensures no background */
  border: none; /* Ensures no border */
  padding: 0; /* Resets any padding */
  outline: none; /* Removes focus outline */
  transition: color 0.3s ease; /* Smooth transition for the background color */
}

/* Hover state */
.social-icons a:hover {
  color: #66cc00; /* Changes the color to #66cc00 when hovered */
}

@media (max-width: 431px) {
  .logo {
    height: 108%;
  }
  .nav-buttons button {
    font-size: 1.3vh;
    font-weight: bold;
  }

  .social-icons a {
    font-size:4vh;
    margin-left: 15px; /* Sets the font size of social icons to 3vh */
  }
}