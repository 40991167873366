/* ServiceCard.css */
.service-card {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2vw;
  gap: 1vh; /* Spacing between elements */
  height: 32vh;
  border-radius: 20px; /* Slight rounding of corners */
  transition: all 0.3s ease; /* Smooth transition effect */
  border-width: 2px;
  border-style: solid;
  border-color: #0f1e32; /* Gradient border */
}

.service-card:hover {
  border-color: #66cc00 ; /* Change gradient on hover */
  transform: scale(1.05); /* Scale up on hover */
}

.service-image {
  margin-top: -20px;
  height: 10vw; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

.service-title {
  color: #0f1e32;
  font-size: 1.5vw; /* Smaller font size for the title */
  margin-top: -20px; /* Adjust margin as needed */
}

.service-description {
  color: #0f1e32;
  margin-top: -15px;
  font-size: 1vw; /* Smaller font size for the description */
}

@media only screen and (max-width: 431px) {
  .service-card {
    margin-top: -3vh;
    margin-bottom: 30px;
    border-radius: 10px;
    font-size: 4vw; /* Adjusted for better readability */
    height: 35vw; /* Allows the card to expand based on content */
    width: 35vw; /* Occupies most of the screen width */
    padding: 5vw; /* Consistent padding relative to the screen size */
  }

  .service-image {
    margin-top: -25px;
    width: 19vh; /* Adjust width instead of height for better control */
    height: auto; /* Let the height adjust based on the width */
    object-fit: cover;
  }

  .service-title, .service-description {
    font-size: 3.5vw; /* Increased font size for readability */
    margin-top: -32px; /* Reduced negative margin */
  }
  
  .service-description {
    display: none; /* Consider displaying it or adjusting based on design */
    padding: 0 5px; /* Add padding for text */
  }
}
