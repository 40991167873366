/* Layout and Flex properties for the hero section */
.hero-section {
  z-index: 1;
  margin-top: -15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjusted to full viewport height */
  position: relative; /* To position background images absolutely within */
  overflow: hidden; /* Ensures no overflow from the absolutely positioned image divs */
}

/* Styles for background images */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 2s ease-in-out; /* Smooth transition for opacity */
  opacity: 0; /* Start invisible, becomes visible when active */
}

/* Adjustments for hero content using Flexbox */
.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  color: black;
  z-index: 1; /* Ensures content is above the images */
  width: 70vh; /* Ensure it takes full width of its parent */
  height: 100%; /* Align content vertically */
  padding: 0 15px; /* Avoid text sticking to the edges */
}

/* Add these selectors and rules for h1 and h2 color customization */
.hero-content h1 {
  font-size: 6vh;
  color:  aliceblue; /* Existing color */
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* Adds shadow */
}

.hero-content h2 {
  font-size: 4vh;
  color: aliceblue; 
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5); /* Lighter shadow */
}

.hero-content h1, .hero-content h2 {
  letter-spacing: 0.05em;
}

/* Centering and adjusting buttons with Flexbox */
.hero-buttons {
  margin-bottom: 10vh;
  display: flex;
  justify-content: center;
  width: 100%; /* Use the full width to center buttons */
  position: absolute;
  bottom: 10%; /* Adjust this value to move buttons higher or lower */
}

.hero-buttons button {
  font-size: 2.5vh;
  padding: 10px 20px;
  border-radius: 10px;
  margin:30px;
  margin-top: -50px;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}
/* Green button styling */
.green-button {
  display: inline-flex; /* Use flexbox to align items inline */
  align-items: center; /* Align items vertically in the center */
  justify-content: center;
  background-color: #66cc00; /* Green */
}

.green-button:hover {
  background-color: rgb(34, 83, 37); /* Darker green on hover */
}

/* Blue button styling */
.blue-button {
  background-color: #0066cc; /* Blue */
}

.blue-button:hover {
  background-color: #092c4e; /* Darker blue on hover */
}


/* Animation keyframes unchanged */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in {
  animation: fadeIn 2s ease-out forwards;
}

.fade-out {
  animation: fadeOut 1s ease-out forwards;
}

@media (max-width: 431px) {
  .hero-content {
    width: 85vw
  }
  .hero-content h1 {
    font-size: 5vh;
    color:  aliceblue; /* Existing color */
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* Adds shadow */
  }
  
  .hero-content h2 {
    font-size: 3vh;
    color: aliceblue; 
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5); /* Lighter shadow */
  }
  
}